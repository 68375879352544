@import '~antd/dist/antd.less';

::-webkit-scrollbar {
    width: 0.5rem;
  }
  
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #686868;
    border-radius: 20px;
  }

  @font-face {
    font-family: "Rubik";
    src: local("Rubik"),
      url("./assets/fonts/Rubik/Rubik-VariableFont_wght.ttf") format("truetype");
    font-weight: 100 900;
    font-display: block;
  } 

  @font-face {
    font-family: "NotoSans-Regular";
    src: local("NotoSans-Regular"),
      url("./assets/fonts/NotoSans/NotoSans-Regular.ttf") format("truetype");
    font-display: block;
  }

  @font-face {
    font-family: "NotoSans-Bold";
    src: local("NotoSans-Bold"),
    url("./assets/fonts/NotoSans/NotoSans-Bold.ttf") format("truetype");
    font-display: block;
  } 
  
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  .ant-dropdown-menu.ant-dropdown-menu-sub.ant-dropdown-menu-vertical{
    background-color: black !important;
    margin-left: -3px;
    box-shadow: 5px 5px 10px rgb(0,0,0, 0.5);
  }

@primary-color: #1d79a5;@link-color: #1DA57A;